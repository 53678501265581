import ScrollReveal from 'scrollreveal'
import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import Aos from 'aos'

export class Animation {

  constructor() {

    this.config()

    //this.images = document.querySelectorAll('[data-parallax-image]');

    // if (this.images.length) {
    //   this.parallax()
    // }

    Aos.init({
      offset: -200,
      easing: 'ease-out'
      }
    )
  }

  config() {
    gsap.registerPlugin(ScrollTrigger);
  }

  parallax() {

    this.images.forEach(image => {
      const startImage = image.getAttribute('data-parallax-start');
      const endImage = image.getAttribute('data-parallax-end');
      const start = startImage ? parseInt(startImage) : -50;
      const end = endImage ? parseInt(endImage) : 50;

      ScrollTrigger.create({
        trigger: image,
        start: 'top 100%',
        end: 'bottom -20%',
        scrub: true,
        onUpdate: self => {

          const progress = self.progress;
          const position = start + (end - start) * progress;

          if (image.querySelector('img')) {
            image.querySelector('img').style.translate = `0 ${position}px`;
          }
        }
      })
    })


  }

}
