import '@css/main.scss';
import '@js/bootstrap';

import {Header} from '@js/components/Header';
import {Slider} from '@js/components/Slider';
import {Accordion} from "@js/components/Accordion";
import {Animation} from "@js/utils/Animation";
import {Filters} from "@js/components/Filters";
import {Archive} from "@js/utils/Archive";
import {Form} from "@js/utils/Form";
import GLightbox from 'glightbox';
import Aos from 'aos'

window.onload = () => {

  new Header()
  new Slider()
  new Accordion()
  new Animation()
  new Filters()
  new Form()
  new Archive()

  GLightbox();
  Aos.init({
      disable: 'mobile',
      duration: 500,
      once: false,
      offset: 180,
      easing: 'cubic-bezier(.14,.71,.44,.9)'
    }
  )

  if (window.location.hash) {
    const hash = window.location.hash.substring(1)

    if (hash) {
      var targetElement = document.getElementById(hash);
      var offsetTop = targetElement.offsetTop;

      targetElement.classList.add('open')

      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth'
      });
    }
  }

  const accordeons = document.querySelectorAll('[accordion]')

  if (accordeons[0] !== undefined) {
    accordeons[0].open = true
  }

}
