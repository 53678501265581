export class Form {
  constructor() {
    this.form = document.getElementById('forminator-module-438')
    this.form_2 = document.getElementById('forminator-module-437')

    if(this.form) {
      this.col = document.querySelector('.forminator-row-last .forminator-col')

      this.a = document.createElement('a')
      this.a.href = '/sinscrire'
      this.a.textContent = "S'inscrire"
      this.a.classList.add('btn')
      this.a.classList.add('btn--grey')
      this.col.appendChild(this.a)
    }

    if(this.form_2) {
      this.col = document.querySelector('.forminator-row-last .forminator-col')

      this.a = document.createElement('a')
      this.a.href = '/connexion'
      this.a.textContent = "Me connecter"
      this.a.classList.add('btn')
      this.a.classList.add('btn--grey')
      this.col.appendChild(this.a)
    }
  }
}
