export class Header {
  constructor() {
    this.burger()
    this.scrollHandler()
    this.search()
  }

  burger() {
    this.header = document.querySelector('body header')
    if (this.header) {

      const nav = document.querySelector('.header-nav__burger')
      const burger = document.querySelector('#burger')
      const menu = document.querySelector('.header-nav__nav')

      if (burger) {

        burger.addEventListener('click', evt => {
          evt.preventDefault()
          burger.classList.toggle('active')
          nav.classList.toggle('expanded')
        })

        this.resizeHandler()

        window.addEventListener('resize', evt => {

          this.resizeHandler()

        })
      }

      const burgerSubmenus = document.querySelectorAll("#menu-menu-principal-1 > .menu-item-has-children")

      burgerSubmenus.forEach(t => {

        const link = t.querySelector('&:scope a')
        link.removeAttribute('href')

        let trigger = t.querySelectorAll('a')[0].offsetHeight
        let height = t.querySelector('.sub-menu').offsetHeight + trigger + 30

        console.log(t.querySelector('.sub-menu').offsetHeight)

        t.style.setProperty('--max-height', height + 'px')
        t.style.setProperty('--init-height', trigger + 'px')

        t.addEventListener('click', e => {

          let submenus = document.querySelectorAll("#menu-menu-principal-1 > .menu-item-has-children")

          submenus.forEach(s => {
            if (s === t && s.classList.contains('open')) {
              let doNothing = null
            } else {
              s.classList.remove('open')
            }
          })

          t.classList.toggle('open')
        })
      })
    }
  }

  resizeHandler() {

    const burger = document.querySelector('#burger')
    const menu = document.querySelector('.header-nav__menu')
    const buttons = document.querySelectorAll('.header-nav > a')

    let breakpoint = burger.getAttribute('data-breakpoint')
    if (window.matchMedia("(max-width: " + breakpoint + "px)").matches) {
      buttons.forEach(b => {
        b.style.display = "none";
      })
      menu.style.display = "none";
      burger.classList.remove('hidden');
      burger.classList.add('show');
    } else {
      buttons.forEach(b => {
        b.style.display = "flex";
      })
      menu.style.display = "block";
      burger.classList.remove('show');
      burger.classList.add('hidden');
    }
  }

  scrollHandler() {

    if (document.documentElement.scrollTop || document.body.scrollTop > 0) {
      this.header.classList.add('scrolled')
    }

    document.addEventListener('scroll', () => {
      let height = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;

      if (height > 0 && !this.header.classList.contains('scrolled')) {
        this.header.classList.toggle('scrolled')
      }
      if (height === 0 && this.header.classList.contains('scrolled')) {
        this.header.classList.toggle('scrolled')
      }
    })

  }

  search() {

    let search = document.querySelector('.header-actions__search')
    let searchModal = document.querySelector('#search-modal')
    let closeSearch = document.querySelector('#close-search')

    search.addEventListener('click', () => {
      searchModal.style.top = '0'
    })

    closeSearch.addEventListener('click', () => {
      searchModal.style.top = '-40%'
    })

  }

}
